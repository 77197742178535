<template>
  <div>
    <div v-if="isShow">
      <Head :title="$t('layout.Set')"></Head>
      <!-- 背景 -->
      <img src="@/image/bg_03.jpg" class="bg" alt="" :style="'width:'+$setWidth">
      <div class="notice" :style="'width:'+$setWidth">
        <div class="notice_box">
          <div class="notice_title common_text">{{$t('setup.text1')}}</div>
          <!-- <div class="notice_text common_text" style="font-size:calc(var(--text) - 2px)" v-html="zifu(item.content)"></div> -->
        </div>
      </div>
      <div class="community" :style="'width:'+$setWidth">
        <div class="community_box" style="justify-content: space-around;">
          <div class="flex_row" >
            <div v-if="info.mtfee!=undefined" :style="'color:'+(info.mtfee<=100?'#00ff00':'#ff0000')">{{'MT'+(info.mtfee<=100?"交易正常":"停止交易")}}, </div>
            <div v-if="info.ulmfee!=undefined" :style="'color:'+(info.ulmfee<=100?'#00ff00':'#ff0000')">{{'ULME'+(info.ulmfee<=100?'交易正常':'停止交易')}}</div>
          </div>
          <div class="flex_row">
            <div class="community_btn flex_center" style="color:#ff0000" @click="stopme(0)">停止交易</div>
            <div class="community_btn flex_center" @click="stopme(1)">恢复交易</div>
          </div>
        </div>
      </div>

      <div class="community" :style="'width:'+$setWidth">
        <div class="community_box flex_row" style="justify-content: space-around;">
        <div v-if="info.withdrawal!=undefined">{{info.withdrawal?"已停止所有提现":"当前提现正常"}}</div>
          <div class="flex_row">
            <div class="community_btn flex_center" style="color:#ff0000" @click="stop(true)">停止提现</div>
            <div class="community_btn flex_center" @click="stop(false)">恢复提现</div>
          </div>
        </div>
      </div>

       <div class="community" :style="'width:'+$setWidth" v-if="info.withdrawal!=undefined&&!info.withdrawal">
        <div class="community_box flex_row" style="justify-content: space-around;">
        <div v-if="info.withdrawalBr!=undefined">{{info.withdrawalBr?"已停止分红提现":"当前分红提现正常"}}</div>
          <div class="flex_row">
            <div class="community_btn flex_center" style="color:#ff0000" @click="stopBr(true)">停止提现</div>
            <div class="community_btn flex_center" @click="stopBr(false)">恢复提现</div>
          </div>
        </div>
      </div>

       <div class="community" :style="'width:'+$setWidth">
        <div class="community_box flex_row" style="justify-content: space-around;">
        <div v-if="info.status!=undefined">{{info.status==0?"LP分红正常":(info.status==1?'LP分红禁止提现':'LP分红禁止所有操作')}}</div>
          <div class="flex_row">
            <div class="community_btn flex_center" style="color:#ff0000" @click="stopLp(1)">停止提现</div>
            <div class="community_btn flex_center" style="color:#ff0000" @click="stopLp(2)">停止操作</div>
            <div class="community_btn flex_center" @click="stopLp(0)">恢复</div>
          </div>
        </div>
      </div>

      <div class="community" :style="'width:'+$setWidth">
        <div class="community_box">
          <div class="community_list flex_row">
            <div>地址</div>
            <input v-model="address1" type="text">
            <div class="community_btn1 flex_center" @click="query(address1)">查询</div>
          </div>
          <div class="community_btn flex_center" @click="addUserW">批准大额提现</div>
        </div>
      </div>

      <div class="community" :style="'width:'+$setWidth">
        <div class="community_box">
          <div class="community_list flex_row">
            <div>地址</div>
            <input v-model="address2" type="text">
            <div class="community_btn1 flex_center" @click="query1(address2)">查询</div>
          </div>
          <div class="community_list flex_row">
            <div class="community_btn flex_center" @click="addUserB(1)">提现黑名单</div>
            <div class="community_btn flex_center" style="background: #a16f6f;" @click="addUserB(0)">移除本地址</div>
          </div>
        </div>
      </div>

       <div class="community" :style="'width:'+$setWidth">
        <div class="community_box">
          <div class="community_list flex_row">
            <div>地址</div>
            <input v-model="address3" type="text">
            <div class="community_btn1 flex_center" @click="query2(address3)">查询</div>
          </div>
          <div class="community_list flex_row">
            <div class="community_btn flex_center" @click="addUserUlmeB(2)">ULME黑名单</div>
            <div class="community_btn flex_center" style="background: #a16f6f;" @click="addUserUlmeB(0)">移除本地址</div>
          </div>
        </div>
      </div>


      <div class="community" :style="'width:'+$setWidth">
        <div class="community_box">
          <div class="community_list flex_row">
            <div>社区名称</div>
            <input v-model="title" type="text">
          </div>
          <div class="community_list flex_row">
            <div>社区地址</div>
            <input v-model="address" type="text">
          </div>
          <div class="community_btn flex_center" @click="add">添加社区</div>
        </div>
      </div>

     
     <div class="community" :style="'width:'+$setWidth">
        <div class="community_box">
          <div class="community_list flex_row">
            <div>地址</div>
            <input v-model="address88" type="text">
          </div>
          <div class="community_btn flex_center" @click="add88(0)">添加88超级布道者</div>
        </div>
      </div>

      <div class="community" :style="'width:'+$setWidth">
        <div class="community_box">
          <div class="community_list flex_row">
            <div>地址</div>
            <input v-model="address99" type="text">
          </div>
          <div class="community_btn flex_center" @click="add88(1)">添加99社区节点</div>
        </div>
      </div>

      <div class="community" :style="'width:'+$setWidth">
        <div class="community_box">
          <div class="community_btn flex_center" @click="adjustment()">NFT提现费分红</div>
        </div>
      </div>

      <div class="community" :style="'width:'+$setWidth">
        <div class="community_box">
          <div class="community_list flex_row">
            <input v-model="ulmMin" type="text">
          <div class="community_btn flex_center" :style="ulmMin==''||ulmMin==ulmMin1?'background-color: #999999;':''" :disabled="ulmMin==''||ulmMin==ulmMin1" @click="setUlmMin">设置310最小产出</div>
          </div>
        </div>
      </div>

      <div class="community" :style="'width:'+$setWidth">
        <div class="community_box">
          <div class="community_list flex_row">
            <input v-model="mt_price" type="text">
          <div class="community_btn flex_center" :style="mt_price==''||mt_price==mt_price1?'background-color: #999999;':''" :disabled="mt_price==''||mt_price==mt_price1" @click="setPrice">设置390MT价格</div>
          </div>
        </div>
      </div>
      <!-- <div class="community" :style="'width:'+$setWidth">
        <div class="community_box">
          <div class="community_btn flex_center" @click="addMinter(0)">设置原分红</div>
          <div class="community_btn flex_center" @click="addMinter(1)">设置原分红1</div>
          <div class="community_btn flex_center" @click="addMinter(2)">设置原分红2</div>
        </div>
      </div> -->

      <!-- <div class="community" :style="'width:'+$setWidth">
        <div class="community_box">
          <div class="community_btn flex_center" @click="edit80(0)">1修正80</div>
          <div class="community_btn flex_center" @click="edit80(1)">2修正80</div>
          <div class="community_btn flex_center" @click="edit80(2)">3修正80</div>
        </div>
      </div> -->

      <!-- <div class="community" :style="'width:'+$setWidth">
        <div class="community_box">
          <div class="community_btn flex_center" @click="setFee()">设置MT卖</div>
          <div class="community_btn flex_center" @click="setFee1()">设置MT交易</div>
        </div>
      </div> -->


    </div>

    	<!-- 密码 -->
    <div class="bind flex_center" v-if="show_bind" @touchmove.prevent @mousewheel.prevent>
      <div class="mask"></div>
      <div class="bind_bg">
        <div class="bind_box y_center">
          <textarea :placeholder="$t('index.bind_popup.input')" v-model="pwd" />
          <button @click="login()">确定</button>
        </div>
      </div>
    </div>

  </div>
</template>
 
<script>
import walletHelper from "@/utils/walletHelper.js"
import { Loading } from 'element-ui';

export default {
  props:['list'],
  data () {
    return {
      show_bind:true,
      isShow: false,
      number: 0,
      notice:[],
      info:[],
      last_page:0,
      current_page:0,
      wallet:'',
      pwd:'',
      title:'',
      ulmMin:'',
      mt_price:'',
      ulmMin1:'',
      mt_price1:'',
      address:'',
      address1:'',
      address2:'',
      address3:'',
      address88:'',
      address99:'',
      loading:0,
    }
  },
  computed: {
    wallet_address() {
		return this.$store.state.user.wallet_address
	},
  },
  async mounted () {
    await this.$onLaunched;
    this.wallet = this.$store.state.user.wallet_address
    console.log(this.wallet)

  },
  methods: {
    dd(){
        this.loading =Loading.service({
            lock: true,
            text: '交易正在处理中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
    },
    closeLoading(msg){
      if(msg&&msg!=''){
        this.$toast({
            title: msg
        });
      }
      this.loading&&this.loading.close();
    },
    initAll(){
      walletHelper.getContract('daoV1').methods._allPower(18).call().then((result)=>{
        this.ulmMin = walletHelper.Wei(result)
        this.ulmMin1 = this.ulmMin
      })
      walletHelper.getContract('dao390').methods.getProfitLength().call().then(res=>{
        walletHelper.getContract('dao390').methods.getProfit(String(Number(res)-1)).call().then(res=>{
          console.log(res)
          this.mt_price=1/(walletHelper.Wei(res[1])/3.9)
          this.mt_price1 = this.mt_price

        })
      })
    },
    add(){
      const that=this
      if(this.name==''||this.address==''){
        this.$toast('请输入地址')
        return
      }
      if(!walletHelper.getWeb3().utils.isAddress(this.address)){
        this.$toast('输入的地址无效')
        return
      }
      this.sendWeb3(walletHelper.getContract('daoV3').methods.addCommunity(this.address)).then((result)=>{
        that.closeLoading('添加成功')
        if(res&&error==undefined){
          this.get('/api/Community/save', {title:this.title,wallet:this.address}, 'POST').then(res => {
            this.$toast('添加完成')
          })
        }
      })
    },
    add88(is88){
      let address=is88==0?this.address88:this.address99
      const that=this
      if(!walletHelper.getWeb3().utils.isAddress(address)){
        this.$toast('输入的地址无效')
        return
      }
      this.sendWeb3(walletHelper.getContract('daoV3').methods.addCommunity(is88==0?walletHelper.getContractAddress('cc88'):walletHelper.getContractAddress('cc99'),address)).then((result)=>{
        that.closeLoading('添加成功')
      })
    },
    addMinter(is88){
      let address='daoV1'
      if(is88==1){
        address='dd80'
      }else if(is88==2){
        address='cc67Old'
      }
      this.sendWeb3(walletHelper.getContract(address).methods.addMinter(walletHelper.getContractAddress('daoV2bj'))).then((result)=>{
        that.closeLoading('添加成功')
      })
    },
    adjustment(){
      this.sendWeb3(walletHelper.getContract('daoV2bj').methods.adjustment()).then((result)=>{
        that.closeLoading('成功')
      })
    },
    query(address){
      const that=this
      walletHelper.getContract('daoV1').methods.data(address,walletHelper.dataIndex._userLargeWithdrawal).call().then((result)=>{
        let time=0
        if(result>0){
          time=Number(result)-Math.ceil(new Date().getTime()/1000)
          if(time>0){
            time=Math.ceil(time/60)
          }
        }
          this.$toast('该地址'+(time==0?'无大额提现权限':'权限将在'+time+'分钟后到期'))    
      })
    },
    query1(address){
      const that=this
      walletHelper.getContract('dis').methods.white(address).call().then((result)=>{
          this.$toast('该地址'+(result==0?'不在提现黑名单':'无法提现'))    
      })
    },
    query2(address){
      const that=this
      walletHelper.getContract('disNew3').methods.white(address).call().then((result)=>{
          this.$toast('该地址'+(result!=2?'不在黑名单':'无法交易'))    
      })
    },
    addUserW(){//大金额提现
      const that=this
      if(this.address1==''){
        this.$toast('请输入地址')
        return
      }
      this.address1=this.address1.replaceAll(' ','')
      if(!walletHelper.getWeb3().utils.isAddress(this.address1)){
        this.$toast('输入的地址无效')
        return
      }
      this.sendWeb3(walletHelper.getContract('daoV3').methods.addWithdrawal(this.address1,"24")).then((result)=>{
        that.closeLoading('添加成功')
        that.address1==''
      })
    },
    addUserB(num){//提现黑名单
      const that=this
      if(this.address2==''){
        this.$toast('请输入地址')
        return
      }
      this.address2=this.address2.replaceAll(' ','')
      if(!walletHelper.getWeb3().utils.isAddress(this.address2)){
        this.$toast('输入的地址无效')
        return
      }
      this.sendWeb3(walletHelper.getContract('dis').methods.setWhiteV1(String(this.address2),String(num))).then((result)=>{
        that.closeLoading('添加成功')
        that.address2==''
      })
    },
    addUserUlmeB(num){//ulme黑名单
      const that=this
      if(this.address3==''){
        this.$toast('请输入地址')
        return
      }
      this.address3=this.address3.replaceAll(' ','')

      if(!walletHelper.getWeb3().utils.isAddress(this.address3)){
        this.$toast('输入的地址无效')
        return
      }
      this.sendWeb3(walletHelper.getContract('disNew3').methods.setBlack(String(this.address3),String(num))).then((result)=>{
        that.closeLoading('添加成功')
        that.address3==''
      })
    },
    setUlmMin(){//
      const that=this
      if(this.ulmMin==''){
        this.$toast('请输入数量')
        return
      }
      if(this.ulmMin==this.ulmMin1){
        this.$toast('数量没有改变')
        return
      }
      if(this.ulmMin>990000){
        this.$toast('数量设置过大')
        return
      }
      console.log(this.ulmMin)
      this.sendWeb3(walletHelper.getContract('daoV1').methods.setAllPower("18",walletHelper.toWei(String(this.ulmMin)))).then((result)=>{
        that.closeLoading('设置成功')
        that.init()
      })
    },
    setPrice(){//
      const that=this
      if(this.mt_price==''){
        this.$toast('请输入数量')
        return
      }
      if(this.mt_price==this.mt_price1){
        this.$toast('数量没有改变')
        return
      }
      let mt_price=3.9/this.mt_price
      console.log(mt_price,walletHelper.toWei(String(mt_price)))
      this.sendWeb3(walletHelper.getContract('dao390').methods.setProfit(walletHelper.toWei(String(mt_price)))).then((result)=>{
        that.closeLoading('设置成功')
        that.init()
      })
    },
    stop(bn){
      const that=this
      this.sendWeb3(walletHelper.getContract('daoV3').methods.stopWithdrawal(bn)).then((result)=>{
        that.closeLoading(bn?'已恢复所有提现':'已暂停所有提现')
        that.initData()
      })
    },
    stopBr(bn){
      const that=this
      this.sendWeb3(walletHelper.getContract('daoV3').methods.BranchWithdrawal(bn)).then((result)=>{
        that.closeLoading(bn?'已恢复分红提现':'已暂停分红提现')
        that.initData()
      })
    },
    stopLp(bn){
      const that=this
      this.sendWeb3(walletHelper.getContract('daoV2').methods.setStatus(bn)).then((result)=>{
        that.closeLoading('操作成功')
        that.initData()
      })
    },
    stopme(num){
      const that=this
      this.sendWeb3(walletHelper.getContract('daoV3').methods.stopme(walletHelper.getContractAddress('mt'),walletHelper.getContractAddress('ulm'),num)).then((result)=>{
        that.initData()
      })
    },
    edit80(type){
      const that=this
      if(type==1){
        walletHelper.getContract('mt').methods.balanceOf(walletHelper.getContractAddress('dd80')).call().then((balance)=>{
          walletHelper.getContract('daoV1').methods._allPower(7).call().then((_allPowerIndexLpMtDisLastBalance)=>{
            let value=walletHelper.add(_allPowerIndexLpMtDisLastBalance,balance)
            this.sendWeb3(walletHelper.getContract('daoV1').methods.setAllPower(9,value)).then((result)=>{
              this.$toast('成功')
            })
          })
        })
      }else{
        walletHelper.getContract('ulm').methods.balanceOf(walletHelper.getContractAddress('dd80')).call().then((balance)=>{
          walletHelper.getContract('daoV1').methods._allPower(8).call().then((_allPowerIndexLpMtDisLastBalance)=>{
            let value=walletHelper.add(_allPowerIndexLpMtDisLastBalance,balance)
            console.log(value)
            this.sendWeb3(walletHelper.getContract('daoV1').methods.setAllPower(10,value)).then((result)=>{
              this.$toast('成功')
            })
          })
        })
      }
    },
    // setFee(){
    //   const that=this
    //   const fee=[20,20,60]
    //   const add=["0x4EC7dA79B5B9e68B02aA87cB9fa5eD9fF270182E","0xa0f76967e9F36367c9045AdcfEe0F62D17B4F016","0xB56b5Cd919E2Ab61197EDd1Dbc222554a4c87e58"]
    //   this.sendWeb3(walletHelper.getContract('mt').methods.setContractorsFee(fee,add,2)).then((result)=>{
    //   })
    // },
    // setFee1(){
    //   const that=this
    //   const fee=[40,60]
    //   const add=["0x2f3f25046Ea518d1E524B8fB6147c656D6722CeD","0xB56b5Cd919E2Ab61197EDd1Dbc222554a4c87e58"]
    //   this.sendWeb3(walletHelper.getContract('mt').methods.setContractorsFee(fee,add,1)).then((result)=>{
    //   })
    // },
    login(){
      
      if (process.env.VUE_APP_DEBUG) {
        this.show_bind=false
          this.isShow=true
          this.initData()
          return
      }
      if(this.pwd==''){
        this.$toast('输入为空')
         return
      }
      this.get('/api/user/login', {address:walletHelper.getAddress(),pwd:this.pwd}, 'POST').then(res => {
        this.show_bind=false
        this.init()
      })
    },
    init() {
      const that=this

      walletHelper.getContract('daoV3').methods.isMinter(walletHelper.getAddress()).call().then((result)=>{
        if(result){
          that.isShow=true
          that.initData()
        }else{
          walletHelper.getContract('daoV1').methods.isMinter(walletHelper.getAddress()).call().then((result)=>{
            if(result){
              that.isShow=true
              that.initData()
            }else{
              that.$router.push('/')
            }
          },e=>{
            that.$router.push('/')
          })
        }
      },e=>{
        that.$router.push('/')
      })
    },
    initData(){
      const that=this
      walletHelper.getContract('daoV3').methods.withdrawal().call().then((result)=>{
          that.$set(that.info,'withdrawal',result)         
      })
      walletHelper.getContract('daoV3').methods.withdrawalBr().call().then((result)=>{
          that.$set(that.info,'withdrawalBr',result)         
      })
      walletHelper.getContract('daoV2').methods.status().call().then((result)=>{
          that.$set(that.info,'status',result)         
      })
      walletHelper.getContract('daoV3').methods.withdrawalBr().call().then((result)=>{
          that.$set(that.info,'withdrawalBr',result)         
      })
      walletHelper.getContract('mt').methods.getTransactFee().call().then((result)=>{
          that.$set(that.info,'mtfee',result)         
      })
      walletHelper.getContract('ulm').methods.getTransactFee().call().then((result)=>{
        that.$set(that.info,'ulmfee',result)         
      })
      that.initAll()
    },
    zifu(value){
      if(value!=null){
        value = value.replace(/&amp;/g, '&')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&quot;/g, '"')
            .replace(/&#39;/g, "'")
            .replace(/&nbsp;/g, ' ')
        return value
      }else{
        return value
      }
    }
  }
}
</script>
 
<style lang="scss" scoped>
  .bg{
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    margin: auto;
  }
  .notice{
    padding: 10px;
    width: 100%;
    margin: 0 auto;
    .notice_box{
      background-color: rgba(#1A328B,0.5);
      color: #ffffff;
      border-radius: 10px;
      padding: 0 10px;
      margin-top: 10px;
      .notice_title{
        padding: 10px 0;
        margin-bottom: 5px;
        border-bottom: 1px solid #1A328B;
      }
      .notice_text{
        padding: 3px 0;
      }
    }
  }
  .community{
    padding: 10px;
    margin: 0 auto;
    .community_box{
      background-color: rgba(#1A328B,0.5);
      color: #ffffff;
      padding: 10px;
      border-radius: 10px;
      .community_list{
        width: 100%;
        font-size: 16px;
        padding: 5px 0;
        input{
          margin-left: 20px;
          width: 0;
          flex: 1;
          font-size: 16px;
          background: #01122A;
          padding: 10px;
          border-radius: 5px;
          color: #ffffff;
        }
      }
      .community_btn{
        // width: 90%;
        background: #00BABC;
        margin: 5px 5px;
        padding: 10px;
        border-radius: 5px;
      }
      .community_btn1{
        width: 20%;
        background: #00bc65;
        margin: 5px 5px;
        padding: 10px;
        border-radius: 5px;
      }
    }
  }

  .bind{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1000;
  .bind_bg{
    background: rgba(#00BABC,0.7);
    padding: 6px;
    border-radius: 7px;
    position: relative;
    z-index: 2;
    width: 90%;
    max-width: 300px;
    .bind_box{
      background: rgba(#000000,0.7);
      z-index: 1000;
      padding: 15px 10px;
      border-radius: 7px;
      font-size: 16px;
      textarea{
        font-size: 16px;
        // width: 0;
        // flex: 1;
        width: 100%;
        padding: 15px 15px;
        background: rgba(#000000,0.7);
        border-radius: 7px;
        min-height: 66px;
        color: #00DEFF;
        line-height: 18px;
        margin: 15px 0;
      }
      // chrome
      textarea::-webkit-input-placeholder { /* WebKit browsers */
        color: rgba(#00DEFF,0.7);
        font-size:15px;
      }
      // firefox
      textarea:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: rgba(#00DEFF,0.7);
        font-size:15px;
      }
      button{
        background: rgba(#00DEFF,0.7);
        color: #000000;
        font-size: 16px;
        border: none;
        margin-left: 10px;
        border-radius: 7px;
        height: 40px;
        // width: 80px;
        width: 100%;
      }
    }
  }
}
</style>